import React from "react";
import styled from "styled-components";
import MainMenu from "../MainMenu/MainMenu";
import taxiLogo from "../../Assets/taxi-logo.svg";


const AppHeader = () => {

    return (
        <Header>
            <LogoImage><img src={taxiLogo}/></LogoImage>
            <MainMenu/>
        </Header>
);
}

const LogoImage = styled.div`
position: absolute;
width:65px;
height:auto;
left: 3vw;
top: 5px;
`

const Header = styled.div`
width: 100vw;
height: 80px;
position:fixed;
left: 0px;
z-index:20;
background-color: #22242e;
border-bottom: 1px solid #2f3240;
`

export default AppHeader;