import React, { useState } from "react";
import styled from "styled-components";
import purpleChessImage from "../../Assets/purple-chess.svg";
import useServices from "./hooks/services";
import { useTranslation } from "react-i18next";
import circleDotIcon from "../../Assets/circle-dot-regular.svg";
import 'animate.css';
import devices from "../../style/devices";
import {Select} from "antd";
import {Option} from "antd/es/mentions";


const ServicesPanel = () =>{
    const { t } = useTranslation();
    const [selectedService, setSelectedService ] = useState(0);

    const serviceSelectHandler = (serviceIndex) =>
    {
        setSelectedService(serviceIndex);
    };

    const handleChange = (option) => {
        setSelectedService(option);
    }

    const { servicesList } = useServices();

    return(
        <div>
            <PanelContainer id="services-panel">
                <h1 className="mobile-header">{t("servicesPanel.services")}</h1>
            <FlexContainer>
                <ServiceListContainer>
                    <h1>{t("servicesPanel.services")}</h1>
                    <ServiceList>
                        {servicesList.map((serviceListItem, index) => {
                            return <ServiceListItem onClick={()=> {serviceSelectHandler(index)}}>
                                {serviceListItem.title}
                            </ServiceListItem>
                        })}
                    </ServiceList>
                </ServiceListContainer>
                <DropdownSelect
                    className="mobile-dropdown"
                    defaultValue={0}
                    dropdownStyle={{ fontFamily: 'Arial'}}
                    onChange={handleChange}>
                    {servicesList.map((service, index) => {
                        return <Option value={index}>{service.title}</Option>
                    })}
                </DropdownSelect>
                <ServiceDescriptionContainer>
                    {servicesList.map((service, index) => {
                        return <ServiceDescription
                            className={selectedService === index? "animate__animated animate__fadeInUp selectedServiceDesc" : "animate__animated animate__fadeInUp"}
                        >
                            <h1><i className={service.icon}></i>&nbsp;&nbsp;{service.title}</h1>
                            <br/>
                            <p>{service.description}</p>
                        </ServiceDescription>})}
                </ServiceDescriptionContainer>
                <ServiceImageContainer>
                    {servicesList.map((serviceListItem, index) => {
                        return <ServiceImage
                            className={selectedService === index? "animate__animated animate__fadeInDown selectedServiceImage" : "animate__animated animate__fadeInDown"}
                            src={serviceListItem.image}
                        />
                    })}
                </ServiceImageContainer>
            </FlexContainer>
        </PanelContainer>
        <PurpleChessPattern/>
        </div>
    )
}

const PurpleChessPattern = styled.div`
width:100vw; 
height: 50px;
background: url(${purpleChessImage});
`
const ServiceListContainer = styled.div`
width: 20vw;
min-height: 45vh;
& > h1
{
color: white;
padding-left:5vw;
font-size: 3.2vh;
line-height: 3.2vh;
}

@media ${devices.mobile} {
width: 90vw;
display: none;
order: 1;
}
`
const ServiceList = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
width: 100%;
height: auto;
`

const ServiceDescriptionContainer = styled.div`
width: 30vw;
height: 45vh;

@media ${devices.mobile} {
width: 90vw;
order: 3;
margin-bottom: 50px;
text-align: center !important;
height: auto !important;
}
`

const ServiceDescription = styled.div`
color: white;
text-align: justify;
white-space: pre-line;
font-size: 2.3vh;
line-height: 2.8vh;

& h1
{
color: white;
text-align: center;
}

display: none;

&.selectedServiceDesc
{
display: inline-block !important;
}
`

const ServiceImageContainer = styled.div`
width: 25vw;
height: 45vh;
overflow: hidden;
@media ${devices.mobile} {
height: 280px;
padding: 10px;
width: 100%;
margin: 0 auto;
order: 2
}
`

const ServiceImage = styled.img`
display: none;
height: 100%;
width: 100%;
vertical-align: middle;

@media ${devices.mobile} {
height: 100%;
width: 100%;
}

&.selectedServiceImage {
display: inline-block;
vertical-align: middle;
}
`

const ServiceListItem = styled.div`
color: #d9d9d9;
font-size: 2.5vh;
padding: 10px;
background: url(${circleDotIcon}) no-repeat 0px 18px;
background-size: 20px;
padding-left: 30px;
cursor: pointer;
transition: all 0.5s;
transform-origin: left;
&:hover
{
color: white;
transform: scale(1.3);
}
& p {
line-height: 3vh;
}

@media ${devices.mobile} {
font-size: 2vh;
line-height: 2vh;
background-size: 15px;
}


`

const PanelContainer = styled.div`
    width:100%;
    position:relative;
    box-sizing: border-box;
    background: #191829;
    z-index:1;
    font-family: 'Montserrat', sans-serif, regular;
    padding: 4%;
    
    & .mobile-header {
        display:none;
    }
    
    @media ${devices.mobile} { 
    height: auto;
   
    & .mobile-header {
    color: white;
    text-align: center;
    width: 100%;
    font-size: 4vh;
    display: inline-block;
    }
}
`

const FlexContainer = styled.div`
display: flex;
justify-content: space-between;

& > div
{
// border: 2px solid red;
}

@media ${devices.mobile} {
flex-direction: column;
}
`

const DropdownSelect = styled(Select)`
display:none;
width: 100% !important;

& .ant-select-selector {
  background-color: #272640 !important;
  color: white;
  font-weight: 600;
}

& .ant-select-arrow {
  color: white;
}

& .ant-select-selection {
  background-color: transparent;
}

@media ${devices.mobile} { 
    display: inline-block;
}`

export default ServicesPanel;