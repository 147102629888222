import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {NavLink, useNavigate } from "react-router-dom";
import devices from "../../style/devices";
import iconEN from "../../Assets/gb.svg";
import iconSR from "../../Assets/rs.svg";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const MainMenu = () =>{
    const { t, i18n } = useTranslation();

    const changeLanguageHandler = (languageCode) => {
        localStorage.setItem("language", languageCode);
        i18n.changeLanguage(languageCode);
        setCollapsed(true);
    };

    const menuItems = [
        {
            title: t("main.homepage"),
            icon: "fas fa-taxi",
            panelId: "first-panel",
            key: 'home'
        },
        {
            title: t("main.services"),
            icon: "fas fa-clipboard-list",
            panelId: "services-panel",
            key: "services"
        },
        {
            title: t("main.pricesList"),
            icon: "fas fa-hand-holding-usd",
            panelId: "prices-panel",
            key: "prices"
        },
        {
            title: t("main.bookRide"),
            icon: "fas fa-calendar-alt",
            panelId: "booking-panel",
            key: "booking"
        },
        {
            title: t("main.gallery"),
            icon: "fas fa-photo-video",
            panelId: "gallery-panel",
            key: "gallery"
        },
        {
            title: t("main.contact"),
            icon: "fas fa-info-circle",
            panelId: "map-panel",
            key: "map"
        }
    ];

    const [collapsed, setCollapsed] = useState(true);
    const navigation = useNavigate()

    const collapseHandler = () => {
        if (collapsed === false){
            setCollapsed(true);
        }
        else
        {
            setCollapsed(false);
        }
    }

    useEffect(() => {
        setCollapsed(true);
    }, [navigation])


    return(
        <>
        <MenuContainer>
            {menuItems.map((menuItem, index) => {
                return <NavLink to={'/' + menuItem.key}><MenuItem><i className={menuItem.icon}/>&nbsp;&nbsp;{menuItem.title }</MenuItem></NavLink>
            })}
            <MenuItem onClick={() => changeLanguageHandler("en")}> <img src={iconEN}/> EN</MenuItem>
            <MenuItem onClick={() => changeLanguageHandler("sr")}> <img src={iconSR}/> SR</MenuItem>
            <BurgerMenuIcon onClick={() => collapseHandler(collapsed)}>
                <FontAwesomeIcon icon={faBars}/>
            </BurgerMenuIcon>
        </MenuContainer>
        <OffsetMenu className={collapsed ? 'collapsed' : 'expanded'}>
            {menuItems.map((menuItem, index) => {
                return <NavLink to={'/' + menuItem.key}><OffsetMenuItem><i className={menuItem.icon}/>&nbsp;&nbsp;{menuItem.title }</OffsetMenuItem></NavLink>
            })}
            <OffsetMenuItem onClick={() => changeLanguageHandler("en")}> <img src={iconEN}/> EN</OffsetMenuItem>
            <OffsetMenuItem onClick={() => changeLanguageHandler("sr")}> <img src={iconSR}/> SR</OffsetMenuItem>
        </OffsetMenu>
        </>
    );
}

const MenuContainer = styled.div`
color:white;
display:flex;
flex-direction: row;
position: absolute;
right: 3vw;
top: 15px;
`
const MenuItem = styled.div`
width: auto;
height: 40px;
margin-left: 1vw;
border-radius: 5px;
background-color: RGBA(255, 255, 255, 0.1);
text-align: center;
vertical-align: middle;
color: white;
line-height: 30px;
padding: 6px 10px 0 10px;
cursor: pointer;
transition: all 0.5s;

& > img 
{
  width: 17px;
  height: auto;
}

&:hover
{
    background-color: RGBA(255, 255, 255, 0.3);
}

color: white;
text-decoration: none !important;


@media ${devices.mobile} {
visibility: hidden;
}

@media ${devices.desktop} {

}
`

const BurgerMenuIcon = styled.div`
height: 50px;
width: 50px;
background-color: white;
position: absolute;
cursor: pointer;
font-size: 30px;
line-height:30px;
border-radius: 5px;
text-align: center;
right: 0px;
padding-top: 10px;
background-color: RGBA(255, 255, 255, 0.1);
color: white;
visibility: hidden;

&:hover
{
    background-color: RGBA(255, 255, 255, 0.3);
}

@media ${devices.mobile} {
visibility: visible;
}
`

const OffsetMenu = styled.div`
width: 100vw;
height: 100vh;
background-color: #2f3240;
position:absolute;
top: 80px;
transform: translate(100%);
transition: all 0.5s;

&.expanded
{
    transform: translate(0%);
}
&.collapsed
{
    transform: translate(100%);
}

@media ${devices.desktop} {
visibility: hidden;
}

@media ${devices.mobile} {
visibility: visible;
}
`

const OffsetMenuItem = styled.div`
width: 100%;
height: 60px;
padding: 20px !important;
border-bottom: 1px solid #42465c;
color: white;
font-size: 16px;
line-height: 16px;
font-family: Montserrat;
padding: 5px 0 0 2.5vw;
box-sizing: border-box;
cursor: pointer;

&:hover
{
background-color: #42465c;
}

& > img 
{
  width: 25px;
  height: auto;
}
`

export default MainMenu;