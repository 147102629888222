import React from 'react';
import styled from "styled-components";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import {useTranslation} from "react-i18next";
import logoImage from "../../Assets/taxi-logo.svg";

const MapPanel = () => {

    const {t} = useTranslation();

    return (<Wrapper id="map-panel">
        <PanelHeader>
            <h2><i className="fas fa-map-marked-alt"></i> {t("main.whereToFindUs")}</h2>
        </PanelHeader>
    <PanelContainer id="map">
        <MapContainer center={[44.60619, 17.85837]} zoom={15} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[44.60619, 17.85837]}>
                <Popup>
                    <img src={logoImage} width="50"/>
                    <br/>
                    {t("footer.taxiStand")} - {t("footer.citySquareTeslic")}
                </Popup>
            </Marker>
        </MapContainer>
    </PanelContainer>
        </Wrapper>

    )
};

const PanelContainer = styled.div`
height: 70vh;
background-color: #eaeaea;
width: 100%;

& .leaflet-control-zoom
{
margin-top: 30vh;
}

& .leaflet-popup-content {
text-align: center;
font-weight: bold;
font-size: 2vh;
line-height: 5vh;
}
`

const PanelHeader = styled.div`
height:10vh;
width: 100%;
opacity: 0.7;
position:absolute;
background-color: #787796;
color: white;
text-align: center;
padding: 3.25vh;
z-index:3;
& h2 {
    color: white;
    font-size: 3.5vh;
    line-height: 3.5vh;
    font-weight: 600;
    font-family: MontserratThin;
}
`

const Wrapper = styled.div`
position: relative;
`

export default MapPanel;