import React from "react";
import styled from "styled-components";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Image } from 'antd';
import KardialPhoto from "../../Assets/slider-photos/kardial.jpg";
import AirportPhoto from "../../Assets/slider-photos/aerodrom-bl.jpg"
import BingoPhoto from "../../Assets/slider-photos/bingo.jpg";
import BorjaPhoto from "../../Assets/slider-photos/borja.jpg"
import EurohercPhoto from "../../Assets/slider-photos/euroherc.jpg";
import devices from "../../style/devices";
import {useTranslation} from "react-i18next";

const GalleryPanel = () =>
{

    const {t} = useTranslation();
    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 0 // optional, default to 1.
        }
    }

    return (
        <PanelContainer id="gallery-panel">
            <h1><i className="far fa-images"></i>&nbsp;{t("main.gallery")}</h1>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                className="Carousel"
                infinite={true}
                autoPlay={true}
                centerMode={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                deviceType={"desktop"}
                dotListClass="custom-dot-list-style"
                itemClass="photo-slide"
            >
                <SlideContainer>
                    <Image className="photo-s" src={KardialPhoto} width={260} />
                </SlideContainer>
                <SlideContainer>
                    <Image className="photo-s" src={AirportPhoto} width={260} />
                </SlideContainer>
                <SlideContainer>
                    <Image className="photo-s" src={BorjaPhoto} width={260} />
                </SlideContainer>
                <SlideContainer>
                    <Image className="photo-s" src={EurohercPhoto} width={260} />
                </SlideContainer>
                <SlideContainer>
                    <Image className="photo-s" src={BingoPhoto} width={260} />
                </SlideContainer>
            </Carousel>
        </PanelContainer>
    );
}

const PanelContainer = styled.div`
height: 70vh;
background-color: #eaeaea;
width: 100%;
padding-top: 10vh;
h1 
{
text-align: center;
font-size: 4vh;
font-weight: 600;
font-family: MontserratThin;
}

& .react-multi-carousel-list
{
width: 80vw;
@media ${devices.mobile} {
width: 100%;
}

margin: 0 auto;
}

& .photo-slide
{
padding: 20px;
}
`

const SlideContainer = styled.div`
height: 260px;
width: 260px;
border-radius: 20px;
overflow: hidden !important;
@media ${devices.mobile} {
width: 180px;
}


`;


export default GalleryPanel;