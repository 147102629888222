import './App.css';
import AppHeader from "./Components/AppHeader/AppHeader";
import { Layout } from 'antd';
import Homepage from "./Pages/Homepage";
import { Routes, Route } from 'react-router';
import AppFooter from "./Components/AppFooter/AppFooter";

const { Header, Footer, Content } = Layout;

function Main() {

    return (
        <div>
            <Layout>
                <Header>
                    <AppHeader></AppHeader>
                </Header>
                <Content>
                    <Routes>
                        <Route path="/" element={<Homepage/>}/>
                        <Route path="/home" element={<Homepage panelId="first-panel"/>}/>
                        <Route path="/services" element={<Homepage panelId="services-panel"/>}/>
                        <Route path="/prices" element={<Homepage panelId="prices-panel"/>}/>
                        <Route path="/booking" element={<Homepage panelId="booking-panel"/>}/>
                        <Route path="/gallery" element={<Homepage panelId="gallery-panel"/>}/>
                        <Route path="/map" element={<Homepage panelId="map-panel"/>}/>
                    </Routes>
                </Content>
                <Footer>
                    <AppFooter/>
                </Footer>
            </Layout>
        </div>
    );
}

export default Main;
