import React, {useRef, useState} from "react";
import styled from "styled-components";
import orderIllustration from "../../Assets/order-svg.svg";
import {Form, Input, Button, DatePicker, TimePicker, Modal} from 'antd';
import {useTranslation} from "react-i18next";
import devices from "../../style/devices";
import {bookRide} from "../../service/bookingService";
import statusCode from "../../service/statusCode";
import { Spin } from 'antd';

const BookingPanel = () => {

    const [loading, setLoading] = useState(false);
    const [bookingTime, setBookingTime] = useState(null);
    const { t } = useTranslation();
    const buttonRef = useRef();
    const formRef = useRef();
    const timePickerRef = useRef();

    const loadingSpinner = <Spin/>;
    const submitHandler = async (obj) => {
        buttonRef.current.disabled = true;
        setLoading(true);
        const response = await bookRide(obj);


        if(response.status === statusCode.OK)
        {
            setLoading(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            Modal.success({
                content: t("bookingPanel.bookingSuccess"),
                onOk: ()=> {
                    buttonRef.current.disabled = false;
                    formRef.current.resetFields();
                }
            });
        }
        else {
            setLoading(false);
            Modal.error({
                content: t("bookingPanel.bookingFail"),
                onOk: ()=> {
                    buttonRef.current.disabled = false;
                }
            });
        }

    }

    const timeChangeHandler = (time) => {
        setBookingTime(time);
    }

    return <PanelContainer id="booking-panel">
        <h1><i className="fas fa-user-clock"></i>&nbsp;&nbsp;{t("bookingPanel.bookYourRide")}</h1>
        <OrderFormContainer>
            <Form ref={formRef} onFinish={submitHandler}>
                <Form.Item
                    className="email-input"
                    label={t("bookingPanel.email")}
                    name="email"
                    rules={[{ required: true, message: t("bookingPanel.mandatoryField") }]}
                >
                    <Input placeholder="Email"/>
                </Form.Item>
                <Form.Item
                    className="phone-input"
                    label={t("bookingPanel.phoneNumber")}
                    name="phone"
                    rules={[{ required: true, message: t("bookingPanel.mandatoryField") }]}
                >
                    <Input placeholder={t("bookingPanel.phoneNumber")}/>
                </Form.Item>
                <Form.Item
                    label={t("bookingPanel.startLocation")}
                    className="destination"
                    name="start"
                    rules={[{ required: true, message: t("bookingPanel.mandatoryField") }]}
                >
                    <Input placeholder="Lokacija polaska"/>
                </Form.Item>
                <Form.Item
                    label={t("bookingPanel.startDate")}
                    name="date"
                    className="picker"
                    rules={[{ required: true, message: t("bookingPanel.mandatoryField") }]}
                >
                    <DatePicker format="DD/MM/YYYY" placeholder={t("bookingPanel.selectDate")}/>
                </Form.Item>
                <Form.Item
                    className="picker"
                    label={t("bookingPanel.startTime")}
                    name="time"
                    rules={[{ required: true, message: t("bookingPanel.mandatoryField") }]}
                >
                    <TimePicker showNow={false} onBlur={()=> {}} value={bookingTime} onSelect={timeChangeHandler} format="HH:mm" placeholder={t("bookingPanel.selectTime")} />
                </Form.Item>
                <Form.Item
                    className="destination"
                    label={t("bookingPanel.destination")}
                    name="destination"
                    rules={[{ required: true, message: t("bookingPanel.mandatoryField") }]}
                >
                    <Input placeholder="Destinacija"/>
                </Form.Item>
                <Form.Item>
                    <Button
                        className="submit-button"
                        type="primary"
                        htmlType="submit"
                        ref={buttonRef}>
                        {loading? loadingSpinner : t("bookingPanel.book")}
                    </Button>
                </Form.Item>
            </Form>
        </OrderFormContainer>
        <OrderIllustration/>
    </PanelContainer>

}

const PanelContainer = styled.div`
width: 100%;
min-height: 90vh;
background-color: #191829;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding: 5%;

& .ant-picker-date-panel
{
    background-color: red !important;
}

& h1
{
    color: white;
    font-size: 4vh;
    font-family: 'MontserratThin';
    text-align: center;
    width: 100%;
    @media ${devices.mobile} {
        order: 1;
        font-size: 3.5vh;
    }
}
`

const OrderFormContainer = styled.div`
width: 45%;
min-width: 350px;
height: 100%;
padding: 2%;

& .ant-input
{
    font-family: 'MontserratRegular';
}
& .ant-form-item-label
{
font-family: 'MontserratThin';
padding-bottom: 10px;
width: 100%;
text-align: left;
}
& .ant-form-item-label label
{
color: white;
display: block;
font-weight: 600;
font-size: 3vh;
}
& .picker
{
width: 50%;
float: left;
}
& .email-input {
    width: 45%;
    float:left;
}
& .phone-input {
    width: 45%;
    float: right;
}
& .destination
{
    clear:both;
}
& .ant-form-item
{
   margin-bottom: 10px;
}

& label::before
{
    display:none !important;
}
& .submit-button
{
    height: 50px;
    width: 200px !important;
    font-size: 2.5vh;
    font-weight: bold;
    float: right;
    margin-top: 10px;
}
@media ${devices.mobile} {
order: 3;
}
`

const OrderIllustration = styled.div`
width: 45%;
min-width: 350px;
height: 60vh;
background: url(${orderIllustration}) no-repeat center;
background-size: contain;
@media ${devices.mobile} {
order: 2;
height: 20vh;
}
`

export default BookingPanel;