import React from 'react';
import styled from "styled-components";
import logoImage from "../../Assets/taxi-logo.svg";
import devices from "../../style/devices";
import {useTranslation} from "react-i18next";

const AppFooter = () => {

    const {t} = useTranslation();

    return(
        <FooterContainer>
            <LogoContainer/>
            <InfoContainer>
                <p><i className="fas fa-clock"/>&nbsp;&nbsp;<b>{t("footer.workingHours")}:</b>&nbsp;&nbsp;{t("footer.monday")} - {t("footer.sunday")} 00:00h - 24:00</p>
                <p><i className="fas fa-map-marker-alt"/>&nbsp;&nbsp;<b>{t("footer.taxiStand")}:</b>&nbsp;&nbsp;{t("footer.citySquareTeslic")}</p>
                <p><i className="fas fa-envelope"/>&nbsp;&nbsp;<b>{t("footer.email")}:</b>&nbsp;&nbsp;taxineso90@gmail.com</p>
                <p><i className="fas fa-phone"/>&nbsp;&nbsp;<b>{t("footer.phone")}:</b>&nbsp;&nbsp;065 85 85 75</p>
            </InfoContainer>
            <FooterBottom>
                <p>Copyright © 2022 TaxiTeslic</p>
            </FooterBottom>
        </FooterContainer>
    )
}

const FooterContainer = styled.div`
position: relative;
width: 100%;
height: auto;
display: flex;
flex-wrap: wrap;
justify-content: space-around;
background-color: #2b2a3d;
padding: 0;
margin: 0px !important;
`

const FooterBottom = styled.div`
width: 100%;
height: 20%;
background-color: #191829;
color: white;
font-family: MontserratThin;
text-align:center;
padding: 2.5vh;
`

const LogoContainer = styled.div`
width: 300px;
height: 300px;
background: url(${logoImage}) no-repeat center;
background-size: 35%;
@media ${devices.mobile} {
height: 160px;
}
`

const InfoContainer = styled.div`
width: 300px;
height: 270px !important;
color:white;
font-family: MontserratThin;
font-size: 2vh;
padding-top: 5%;
box-sizing: border-box;
@media ${devices.mobile} {
height: 200px;
}
`

export default AppFooter;
