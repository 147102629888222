import AirportImage from "../../../Assets/services/airport.svg";
import TouristImage from "../../../Assets/services/tourist.svg";
import PersonalDriverImage from "../../../Assets/services/personalDriver.svg";
import BusinessImage from "../../../Assets/services/business-1.svg"
import CourierImage from "../../../Assets/services/courier.svg";
import {useTranslation} from "react-i18next";

const useServices = () => {

    const {t} = useTranslation();

    const servicesList = [
        {
            title: t("servicesPanel.airportTransfers"),
            image: AirportImage,
            description: t("servicesPanel.airportTransfersDesc"),
            icon: "fas fa-plane-departure"

        },
        {
            title: t("servicesPanel.touristDestinations"),
            image: TouristImage,
            description: t("servicesPanel.touristDesc"),
            icon: "fas fa-suitcase-rolling"
        },
        {
            title: t("servicesPanel.personalDriver"),
            image: PersonalDriverImage,
            description: t("servicesPanel.personalDriverDesc"),
            icon: "fas fa-car"
        },
        {
            title: t("servicesPanel.businessTransfers"),
            image: BusinessImage,
            description: t("servicesPanel.businessTransfersDesc"),
            icon: "fas fa-suitcase"

        },
        {
            title: t("servicesPanel.courierServices"),
            image: CourierImage,
            description: t("servicesPanel.courierServicesDesc"),
            icon: "fas fa-box-open"
        }
    ];

    return {
        servicesList
    }
}

export default useServices;
