import React from 'react';
import styled from "styled-components";
import devices from "../../style/devices";
import { useTranslation } from "react-i18next";

const PricesListPanel = () => {

    const {t} = useTranslation();

    return(
        <PanelContainer id="prices-panel">
            <h1><i className="fas fa-hand-holding-usd"></i>&nbsp;{t("pricesPanel.prices")}</h1>
            <br/>
            <Flexbox>
            <TariffPlan>
                <TariffPlanHeader>
                    <p><i className="fas fa-clipboard-list"></i>&nbsp;{t("pricesPanel.standardTariff")}</p>
                </TariffPlanHeader>
                <TariffPlanContent>
                    <p><i className="fas fa-check"></i>&nbsp;{t("pricesPanel.start")} <b>2 {t("pricesPanel.kmCurrency")}</b></p>
                    <p><i className="fas fa-check"></i>&nbsp;{t("pricesPanel.kilometer")} <b>1.50 {t("pricesPanel.kmCurrency")}</b></p>
                    <p><i className="fas fa-check"></i>&nbsp;{t("pricesPanel.waiting")} <b>10 {t("pricesPanel.kmCurrency")} {t("pricesPanel.perHour")}</b></p>
                    <p><i className="fas fa-check"></i>&nbsp;{t("pricesPanel.ridesLongerThan")} 50 km <b>{t("pricesPanel.negotiablePrice")}</b></p>
                </TariffPlanContent>
            </TariffPlan>
            <OtherServices>
                <TariffPlanHeader>
                    <p><i className="fas fa-user-cog"></i>&nbsp;&nbsp;{t("pricesPanel.customizedServices")}</p>
                </TariffPlanHeader>
                <TariffPlanContent>
                    <p><i className="fas fa-map-marked-alt"></i>&nbsp;&nbsp;{t("servicesPanel.touristDestinations")}: <b>{t("pricesPanel.negotiablePrice")}</b></p>
                    <p><i className="fas fa-plane-departure"></i>&nbsp;&nbsp;{t("servicesPanel.airportTransfers")}: <b>{t("pricesPanel.negotiablePrice")} + {t("pricesPanel.bookingDiscount")}</b></p>
                    <p><i className="fas fa-car"></i>&nbsp;&nbsp;{t("servicesPanel.personalDriver")}: <b>{t("pricesPanel.negotiablePrice")}</b></p>
                    <p><i className="fas fa-business-time"></i>&nbsp;&nbsp;{t("servicesPanel.businessTransfers")}: <b>{t("pricesPanel.negotiablePrice")}</b></p>
                    <p><i className="fas fa-mail-bulk"></i>&nbsp;&nbsp;{t("servicesPanel.courierServices")}: <b>{t("pricesPanel.negotiablePrice")}</b></p>
                </TariffPlanContent>
            </OtherServices>
            </Flexbox>
        </PanelContainer>
    )

}

const PanelContainer = styled.div`
width: 100%;
min-height: 90vh;
height: auto;
background-color: #f2f2f3;
font-family: 'Montserrat', sans-serif, regular;
font-weight: 600;
padding: 5%;
box-sizing: border-box;
position: relative;

& h1 {
text-align: center;
font-size: 4.5vh;
font-weight: 600;
}
`

const Flexbox = styled.div`
display: flex;
justify-content: space-around;
flex-wrap: wrap;
`

const TariffPlan = styled.div`
width: 30vw;
min-width: 350px;
min-height: 55vh;
background-color: #d1d1d1;
border-radius: 20px;
overflow: hidden;
box-shadow: 5px 7px 6px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: 5px 7px 6px -5px rgba(0,0,0,0.75);
-moz-box-shadow: 5px 7px 6px -5px rgba(0,0,0,0.75);
@media ${devices.mobile} {
min-height: 0;
}
`
const TariffPlanHeader = styled.div`
width: 100%;
height: 10vh;
padding-top: 3vh;
background-color: #22242e;
color: white;
text-align: center;
font-size: 4vh;
line-height: 4vh;
@media ${devices.mobile} {
font-size: 2.5vh;
}
`

const TariffPlanContent = styled.div`
width: 100%;
padding: 5vh;
font-size: 3vh;
@media ${devices.mobile} {
font-size: 2vh;
}
`

const OtherServices = styled.div`
width: 55vw;
min-width: 350px;
min-height: 55vh;
background-color: #d1d1d1;
border-radius: 20px;
overflow:hidden;
font-size: 3vh;
box-shadow: 5px 7px 6px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: 5px 7px 6px -5px rgba(0,0,0,0.75);
-moz-box-shadow: 5px 7px 6px -5px rgba(0,0,0,0.75);
& h1 {
font-size: 3.5vh;
}
@media ${devices.mobile} {
margin-top: 40px;
}
`


export default PricesListPanel;