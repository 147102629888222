import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ChooseDriverCard = (props) => {

    const { t } = useTranslation();

    let link;
    let serviceIcon;

    switch (props.type){
        case "viber":
            link = "viber://add?number=" + props.number;
            serviceIcon = "fab fa-viber";
            break;
        case "phone":
            link = "tel://" + props.number;
            serviceIcon = "fas fa-phone";
            break;
    }

    return (
        <a href={link}>
        <CardContainer>
            <TaxiIcon><i className="fas fa-taxi"></i></TaxiIcon>
            <DriverName>{props.driverName}</DriverName>
            <OrderButton><i className={serviceIcon}></i></OrderButton>
        </CardContainer>
        </a>
    )
}

const CardContainer = styled.div`
width: 100%;
height: 60px;
box-sizing: border-box;
border: 1px solid #eaeaea;
padding: 10px;
position:relative;
transition: all 0.5s;
cursor:pointer;
color: black;

&:hover
{
background-color: #ebebeb;
}
`

const DriverName = styled.div`
position: absolute;
left: 50px !important;
top: 21px;
font-size: 18px;
line-height: 18px;
`

const TaxiIcon = styled.div`
font-size: 24px;
`

const OrderButton = styled.div`
position: absolute;
right: 10px;
top: 17px;
font-size: 24px;
line-height: 24px;
`

export default ChooseDriverCard;