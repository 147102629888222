import axios from "axios";
import config from "../util/config";

export const api = () => {
    const baseConfig = {
        baseURL: config.BASE_ENDPOINT_URL,
    };
    const createInstance = (baseConfig) => {
        const instance = axios.create(baseConfig);
        instance.defaults.headers.common["Content-Type"] = "application/json";
        instance.defaults.headers.common["Access-Control-Allow-Headers"] = "*"
        instance.interceptors.request.use(
            async (config) => {
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );
        return instance;
    };

    return createInstance(baseConfig);
};

export default api;
