import React, { useState } from 'react';
import { Modal} from 'antd';
import 'antd/dist/antd.css';
import styled from "styled-components";
import teslicBackground from "../../Assets/teslic-bg.jpg";
import TaxiLogo from "../../Assets/taxi-logo.svg";
import ViberLogo from "../../Assets/viber-logo-empty.svg";
import PhoneCallIcon from "../../Assets/phone-call-icon.svg";
import carPhoto from "../../Assets/skoda.png";
import devices from "../../style/devices";
import 'animate.css';
import { useTranslation } from "react-i18next";
import ChooseDriverCard from "./ChooseDriverCard/ChooseDriverCard";
import purpleChessImage from "../../Assets/purple-chess.svg";

const FirstPanel = () =>{

    const { t } = useTranslation();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPhoneModalVisible, setIsPhoneModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showPhoneModal = () => {
        setIsPhoneModalVisible(true);
    };

    const handlePhoneOk = () => {
        setIsPhoneModalVisible(false);
    };

    const handlePhoneCancel = () => {
        setIsPhoneModalVisible(false);
    };

    return(
        <div id="first-panel">
        <PanelContainer>
            <CarImage>
                <img src={carPhoto}/>
            </CarImage>
            <OrderPanel className="animate__animated animate__fadeInDown">
                <img src={TaxiLogo} width="40%"/>
                <h1>{t("homepage.bookYourRideNow")}</h1>
                <ViberButton onClick={showModal}>{t("homepage.usingViber")}</ViberButton>
                <br/>
                <PhoneButton onClick={showPhoneModal}>{t("homepage.usingPhoneCall")}</PhoneButton>
            </OrderPanel>
            <TextPanel>
                <h1 className="animate__animated animate__jackInTheBox animate__delay-1s">{ t("homepage.taxiServiceForYou")}</h1>
                <br/>
                <p className="animate__animated animate__fadeInDown animate__delay-2s"><i className="fas fa-check"></i> { t("homepage.professionalAndSecure")}</p>
                <br/>
                <p className="animate__animated animate__fadeInDown animate__delay-3s"><i className="fas fa-check"></i> { t("homepage.accuracyAndResponsibility")}</p>
                <br/>
                <p className="animate__animated animate__fadeInDown animate__delay-4s"><i className="fas fa-check"></i> { t("homepage.24/7Availability")}</p>
                <br/>
                <p className="animate__animated animate__fadeInDown animate__delay-5s"><i className="fas fa-check"></i> { t("homepage.fastAndSecure")}</p>
            </TextPanel>
            <PurpleChessPattern/>
        </PanelContainer>
            <Modal title={t("homepage.chooseDriver")} visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <ChooseDriverCard driverName="Nenad Petrović Nešo" type="viber" number="38765858575"/>
            {/*<ChooseDriverCard driverName="Peđa Radojičić" type="viber" number="38765605040"/>*/}
            </Modal>
            <Modal title={t("homepage.chooseDriver")} visible={isPhoneModalVisible} footer={null} onOk={handlePhoneOk} onCancel={handlePhoneCancel}>
                <ChooseDriverCard driverName="Nenad Petrović Nešo" type="phone" number="38765858575"/>
                {/*<ChooseDriverCard driverName="Peđa Radojičić" type="phone" number="38765605040"/>*/}
            </Modal>
        </div>
    );
}

const PanelContainer = styled.div`
    width:100vw;
    height: 92vh;
    position:relative;
    box-sizing: border-box !important;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${teslicBackground});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow: show;
    z-index:2;
    `
const CarImage = styled.div`
width:40%;
display:none;
height:auto;
position: absolute;
right: 10%;
top 50%;
`

const TextPanel = styled.div`
width: 55vw;
height:60vh;
position:absolute;
top: 16vh;
right: 5vw;
box-sizing: border-box;
color: #fae078 !important;
font-family: Montserrat;
text-align:center;
text-shadow: 2px 2px 8px #000000;

@media ${devices.mobile} {
visibility: hidden;
}

& h1 {
font-size: 6vh;
line-height:6vh !important;
color: #fae078 !important;
font-weight: 600;
}

& p {
font-size: 4vh;
line-height: 4vh !important;
font-weight: 600;
}
`

const OrderPanel = styled.div`
width: 440px;
border-radius: 10px;
background-color: #e8e8e8;
position: absolute;
top: 16vh;
left: 5vw;
padding: 20px 2%;
color: white;
box-sizing: border-box;
font-family: 'Montserrat', sans-serif, regular;
text-align: center;
box-shadow: 10px 10px 22px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 10px 10px 22px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 22px 0px rgba(0,0,0,0.75);

& h1 {
font-size: 3vh;
line-height: 5vh !important;
font-weight: bold;
color: black;
}

@media ${devices.mobile} {
left: 0;
right: 0;
margin: auto;
width: 90vw;
}
`
const ViberButton = styled.div`
width: 100%;
height: 10vh;
background-color: #7360f2;
color: white;
padding: 3.5vh 2% 0 2%;
line-height:3vh !important;
box-sizing:border-box;
font-size: 3vh;
font-weight: 600;
cursor: pointer;
border-radius: 15px;
transition: background-color 0.5s;
background-image:url(${ViberLogo});
background-repeat: no-repeat;
background-position: right;

&:hover
{
background-color: #5748b5;
}
@media ${devices.mobile} {
font-size: 2.5vh;
background-size: 70px;
}
`

const PhoneButton = styled.div`
width: 100%;
height: 10vh;
background-color: #37c837;
color: white;
padding: 3.5vh 2% 0 2%;
line-height:3vh !important;
box-sizing:border-box;
font-size: 3vh;
font-weight: 600;
cursor: pointer;
border-radius: 15px;
transition: background-color 0.5s;
background-image:url(${PhoneCallIcon});
background-repeat: no-repeat;
background-position: right;

&:hover
{
background-color: #2ea32e;
}
@media ${devices.mobile} {
font-size: 2.5vh;
background-size: 70px;
}
`
const PurpleChessPattern = styled.div`
width:100%; 
height: 50px;
background: url(${purpleChessImage});
position: absolute;
bottom: 0px;
`

export default FirstPanel;