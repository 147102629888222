import React, {useEffect} from "react";
import OrderPanel from "../Components/FirstPanel/FirstPanel";
import ServicesPanel from "../Components/ServicesPanel/ServicesPanel";
import MapPanel from "../Components/MapPanel/MapPanel";
import GalleryPanel from "../Components/GalleryPanel/GalleryPanel";
import PricesListPanel from "../Components/PriceslistPanel/PriceslistPanel";
import BookingPanel from "../Components/BookingPanel/BookingPanel";
import { BackTop } from 'antd';

const Homepage = (props) => {


    useEffect(() => {
        const panel = document.getElementById(props.panelId);
        const windowHeight = window.innerHeight;
        const scrollPadding = (windowHeight - panel?.offsetHeight) / 2;
        const scrollPosition = panel?.offsetTop - (scrollPadding > 60? scrollPadding : 60);

        if(props.panelId){
            window.scrollTo(0, scrollPosition);
        }
    }, [props.panelId])

    return <div>
        <BackTop/>
        <OrderPanel/>
        <ServicesPanel/>
        <PricesListPanel/>
        <BookingPanel/>
        <GalleryPanel/>
        <MapPanel/>
    </div>

}

export default Homepage;