import api from "./baseService";

export const bookRide = (payload) => {

    const date = new Date(payload.date);
    const formattedDate = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + ".";

    const time = new Date(payload.time)
    const formattedTime = time.toLocaleTimeString();

    const params = new URLSearchParams();
    params.append('email', payload.email);
    params.append('phoneNumber', payload.phone);
    params.append('startLocation', payload.start);
    params.append('date', formattedDate);
    params.append('time', formattedTime);
    params.append('destination', payload.destination);
    return api().post(`/bookRide.php`, params);
};