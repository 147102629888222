import React from "react";
import Main from "./Main";
import { ThemeProvider } from "styled-components";
import initializeI18N from "./i18n/init";
import { BrowserRouter } from "react-router-dom";
import theme from "./style/theme";

initializeI18N();

const App = () => {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Main />
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default App;
